import React, { Component } from 'react'
import './Footer.css';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-main-container">
          <div className="footer-container hours">
            <ul>
              <li className="footer-title">HOURS/DAYS</li>
              <li>Sun Closed</li>
              <li>Mon 12pm–7:30pm</li>
              <li>Tues 10am-7:30pm</li>
              <li>Wed 10am-7:30pm</li>
              <li>Thurs 10am-7:30pm</li>
              <li>Fri 10am-7:30pm</li>
              <li>Sat 10am-6pm</li>
            </ul>
          </div>
          <div className="footer-container contact">
            <ul>
              <li className="footer-title">PHONE</li>
              <li>763-544-1700</li>
              <li className="footer-title">EMAIL</li>
              <li>tj@tjhair.com</li>
            </ul>
          </div>
          <div className="footer-container location">
            <ul>
              <li className="footer-title">LOCATION</li>
              <li>2550 Hillsboro Ln N</li>
              <li>Golden Valley, MN 55427</li>
            </ul>
          </div>
        </div>
        <div className="footer-rights">
          <p>© 2019 TJ Hair Studio | Maintained by <b>JS</b></p>
        </div>
      </footer>
    )
  }
}
