import React, { Component } from 'react'
import './Service.css';

export default class Service extends Component {
  render() {
    return (
      <div className="service">
        <h2>SERVICES AND PRICES</h2>
        <div className="horizontal-line"><hr></hr></div>
        <div className="menu-section">
          <div className="menu-header">HAIRCUT</div>
          <div className="menu-items">
            <div className="menu-item">Women's Cut<span className="item-price">$28</span></div>
            <div className="menu-item">Men's Cut<span className="item-price">$22</span></div>
            <div className="menu-item">Shampoo<span className="item-price">$5+</span></div>
          </div>
          <div className="menu-header">COLOR</div>
          <div className="menu-items">
            <div className="menu-item">Single Process Color<span className="item-price">$50+</span></div>
            <div className="menu-item">Highlights<span className="item-price">Upon consultation</span></div>
          </div>
          <div className="menu-header">TEXTURE</div>
          <div className="menu-items">
            <div className="menu-note">Haircut included</div>
            <div className="menu-item">Women's Perm<span className="item-price">$85+</span></div>
            <div className="menu-item">Men's Perm<span className="item-price">$75+</span></div>
          </div>
          <div className="menu-header">SPECIALTY</div>
          <div className="menu-items">
            <div className="menu-note"></div>
            <div className="menu-item">Permanent Hair Straightening<span className="item-price">$150+</span></div>
          </div>
        </div>
        <div className="horizontal-line"><hr></hr></div>
        <div className="bottom-note">
          For questions about pricing and to schedule an appointment, please call 763-544-1700
        </div>
      </div>
    )
  }
}
