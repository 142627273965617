import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Purpose from './Purpose';
import Service from './Service';
import Contact from './Contact';
import Footer from './Footer';
import './App.css';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/purpose" component={Purpose} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/contact" component={Contact} />
        <Footer />
      </div>
    );
  }
}

export default App;
