import React, { Component } from 'react'
import './Home.css';
import bgImage from './photo/adult-blonde-female-973401-4.jpg';
// import bgImage from './photo/bun-girl-hairs-9634.jpg';


export default class Home extends Component {
  render() {
    return (
      <div className="home">
        <img src={bgImage} alt="background-hair" align="middle" />
      </div>
    )
  }
}
