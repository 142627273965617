import React, { Component } from 'react'
import './Contact.css';
import location from './photo/marker.png';
import phone from './photo/phone.png';
import mail from './photo/new-post.png';


export default class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <h2>CONTACT US</h2>
        <div className="horizontal-line"><hr></hr></div>
        <div className="contact-section">
          <div className="contact-container address">
            <p className="title"><img src={location} alt="location" />Address:</p>
            <p>2550 Hillsboro Ln N</p> 
            <p>Golden Valley, MN 55427</p>
          </div>
          <div className="contact-container phone">
            <p className="title"><img src={phone} alt="phone" />Phone number:</p>
            <p><a href="tel:1-763-544-1700">763-544-1700</a></p>
          </div>
          <div className="contact-container email">
            <p className="title"><img src={mail} alt="mail" />E-mail:</p>
            <p><a href="mailto:tj@hairbytj.com">tj@hairbytj.com</a></p>
          </div>
        
        <div className="map">
          <iframe 
            title="Google Map of TJ Hair Studio"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2820.8816608757893!2d-93.39964128404151!3d45.0070249790983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b335c7a36eae7b%3A0x7008461c1335603!2sTJ+Hair+Studio!5e0!3m2!1sen!2sus!4v1548797210682" 
            // width="600" 
            height="450"
            width="100%" 
            // height="100%" 
            frameborder="0" 
            style={{border: 0}}
            allowfullscreen>
          </iframe>
        </div>
        </div>
        <div className="sm-section">
          <a 
            href="https://www.facebook.com/TJHairStudio/" 
            target="_blank" 
            rel="noopener noreferrer"
            >
            <div className="sm fb"></div>
          </a>
          <a 
            href="https://www.yelp.com/biz/tj-hair-studio-golden-valley-2" 
            target="_blank" 
            rel="noopener noreferrer"
            >
            <div className="sm yelp"></div>
          </a>
          <a 
            href="https://goo.gl/maps/LeEw4eJ8YNA2" 
            target="_blank" 
            rel="noopener noreferrer"
            >
            <div className="sm google"></div>
          </a>
          
        </div>
      </div>
    )
  }
}
