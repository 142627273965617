import React, { Component } from 'react'
import './Header.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    }
  }

  handleMenuClick() {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  handleLinkClick() {
    this.setState({
      menuOpen: false
    })
  }

  render() {
    
    return (
      <header className="header">
        <div className="logo"></div>
        <nav>
          <ul>
            <li><a id="home" className="header-item" href="/">Home</a></li>
            <li><a id="purpose" className="header-item" href="/purpose">Purpose</a></li>
            <li><a id="service" className="header-item" href="/service">Service</a></li>
            <li><a id="contact" className="header-item" href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>
    )
  }
}
